<template>
  <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="fa fa-address-card" aria-hidden="true"></i
        >{{ label.title }}</span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
        <!--begin: Settings-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <el-form ref="form" :model="form" auto-complete="false">
                <template>
                  <el-checkbox-group
                    v-model="form.checkedaType"
                    :min="1"
                    :max="2"
                  >
                    <el-checkbox
                      v-for="(atype, index) in atypes"
                      :label="atype"
                      :key="index"
                      >{{ atype }}</el-checkbox
                    >
                  </el-checkbox-group>
                </template>
                <div
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <div class="row" v-if="showHideFields('Billing Address')">
                    <div class="col-xl-12">
                      <div class="row">
                        <div class="col-xl-12">
                          <h4>Billing Address</h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="row">
                            <div class="col-sm-8">
                              <el-form-item
                                label="Address"
                                prop="address_line_2"
                              >
                                <vue-google-autocomplete
                                  ref="origin_update"
                                  v-model="
                                    form.billing_addresses.address_line_2
                                  "
                                  id="mapUpdate"
                                  classname="form-control el-input__inner"
                                  name="billing_addresses.address_line_2"
                                  placeholder="Enter your Address"
                                  v-on:placechanged="getAddressBillingData"
                                  country="ca"
                                >
                                </vue-google-autocomplete>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('billing_address_line_2')"
                                >Address is required.</span
                              >
                            </div>
                            <div class="col-sm-4">
                              <el-form-item
                                label="Unit Number"
                                prop="address_line_1"
                              >
                                <el-input
                                  v-model="
                                    form.billing_addresses.address_line_1
                                  "
                                  @input.native="capitalize"
                                  auto-complete="nope"
                                  :class="
                                    form.errors.has('billing_address_line_1')
                                      ? 'error '
                                      : ''
                                  "
                                ></el-input>
                              </el-form-item>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4">
                              <el-form-item label="Province" prop="state">
                                <el-select
                                  ref="state"
                                  filterable
                                  v-model="form.billing_addresses.state"
                                  @change="getCityListByStateId('billing')"
                                  :class="
                                    form.errors.has('billing_state')
                                      ? 'error '
                                      : ''
                                  "
                                >
                                  <el-option
                                    v-for="(state, index) in state_list"
                                    :key="index"
                                    :label="state.state_name"
                                    :value="state.state_id"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('billing_state')"
                                >Province is required.</span
                              >
                            </div>
                            <div class="col-sm-4">
                              <el-form-item label="City" prop="city">
                                <el-select
                                  ref="city"
                                  clearable
                                  filterable
                                  v-model="form.billing_addresses.city"
                                  :class="
                                    form.errors.has('billing_city')
                                      ? 'error '
                                      : ''
                                  "
                                >
                                  <el-option class="add-container" value="">
                                    <a
                                      class="add-drop-btn"
                                      @click="CreateCity()"
                                    >
                                      <i class="el-icon-circle-plus"></i>
                                      Add City
                                    </a>
                                  </el-option>
                                  <el-option
                                    v-for="(city, index) in city_billing_list"
                                    :key="index"
                                    :label="city.city_name"
                                    :value="city.city_id"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('billing_city')"
                                >*City is required.</span
                              >
                            </div>

                            <div class="col-sm-4">
                              <el-form-item
                                label="Postal Code"
                                prop="post_code"
                              >
                                <el-input
                                  v-model="form.billing_addresses.post_code"
                                  auto-complete="nope"
                                  maxlength="7"
                                  placeholder="M1K 4P6"
                                  @change="
                                    validPostalbilling(
                                      form.billing_addresses.post_code
                                    )
                                  "
                                  :class="
                                    form.errors.has('billing_post_code')
                                      ? 'error '
                                      : ''
                                  "
                                ></el-input>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('billing_post_code')"
                                >{{
                                  form.errors.get("billing_post_code")
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="showHideFields('Shipping Address')"
                          >
                            <div class="col-sm-12">
                              <el-switch
                                active-text="Shipping address same as billing"
                                v-model="form.shipping_status"
                                @change="setShippingAddress()"
                              ></el-switch>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="showHideFields('Shipping Address')">
                    <div class="col-xl-12">
                      <div class="row">
                        <div class="col-xl-12">
                          <h4>Shipping Address</h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="row">
                            <div class="col-sm-8">
                              <el-form-item
                                label="Address"
                                prop="address_line_2"
                              >
                                <vue-google-autocomplete
                                  ref="shiporigin"
                                  v-model="
                                    form.shipping_addresses.address_line_2
                                  "
                                  id="shipmap1"
                                  classname="form-control el-input__inner"
                                  name="shipping_addresses.address_line_2"
                                  placeholder="Enter your Address"
                                  v-on:placechanged="getAddressShippingData"
                                  country="ca"
                                >
                                </vue-google-autocomplete>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="
                                  form.errors.has('shipping_address_line_2')
                                "
                                >*Address is required</span
                              >
                            </div>
                            <div class="col-sm-4">
                              <el-form-item
                                label="Unit Number"
                                prop="address_line_1"
                              >
                                <el-input
                                  v-model="
                                    form.shipping_addresses.address_line_1
                                  "
                                  @input.native="capitalize"
                                  auto-complete="nope"
                                  :class="
                                    form.errors.has('shipping_address_line_1')
                                      ? 'error '
                                      : ''
                                  "
                                  :disabled="
                                    form.shipping_status ? true : false
                                  "
                                ></el-input>
                              </el-form-item>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-sm-4">
                              <el-form-item label="Province" prop="state">
                                <el-select
                                  ref="state"
                                  filterable
                                  v-model="form.shipping_addresses.state"
                                  @change="getCityListByStateId('shipping')"
                                  :class="
                                    form.errors.has('shipping_state')
                                      ? 'error '
                                      : ''
                                  "
                                  :disabled="
                                    form.shipping_status ? true : false
                                  "
                                >
                                  <el-option
                                    v-for="(state, index) in state_list"
                                    :key="index"
                                    :label="state.state_name"
                                    :value="state.state_id"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('shipping_state')"
                                >*Province is required</span
                              >
                            </div>
                            <div class="col-sm-4">
                              <el-form-item label="City" prop="city">
                                <el-select
                                  ref="city"
                                  clearable
                                  filterable
                                  v-model="form.shipping_addresses.city"
                                  :class="
                                    form.errors.has('shipping_city')
                                      ? 'error '
                                      : ''
                                  "
                                  :disabled="
                                    form.shipping_status ? true : false
                                  "
                                >
                                  <el-option class="add-container" value="">
                                    <a
                                      class="add-drop-btn"
                                      @click="CreateCity1()"
                                    >
                                      <i class="el-icon-circle-plus"></i>
                                      Add City
                                    </a>
                                  </el-option>
                                  <el-option
                                    v-for="(city, index) in city_shipping_list"
                                    :key="index"
                                    :label="city.city_name"
                                    :value="city.city_id"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('shipping_city')"
                                >*City is required</span
                              >
                            </div>

                            <div class="col-sm-4">
                              <el-form-item
                                label="Postal Code"
                                prop="post_code"
                              >
                                <el-input
                                  v-model="form.shipping_addresses.post_code"
                                  auto-complete="nope"
                                  maxlength="7"
                                  :disabled="
                                    form.shipping_status ? true : false
                                  "
                                  placeholder="M1K 4P6"
                                  @change="
                                    validPostalshipping(
                                      form.shipping_addresses.post_code
                                    )
                                  "
                                  :class="
                                    form.errors.has('shipping_state')
                                      ? 'error '
                                      : ''
                                  "
                                ></el-input>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('shipping_post_code')"
                                >{{
                                  form.errors.get("shipping_post_code")
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 15px">
                    <div class="col-sm-12">
                      <button
                        class="
                          btn btn-sm btn-primary
                          font-weight-bold
                          text-uppercase
                        "
                        @click.prevent="submitForm"
                      >
                        {{ label.button }}
                      </button>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Form from "@/core/services/api.form.services";
import {
  getCityList,
  getStateList,
  getCityListByStateId,
  getStateByPrefix,
  getCityByName,
} from "@/api/common";
import { globalEvents } from "@/utils/globalEvents";
import CreateCity from "@/components/DropDown/addcity";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  name: "add_edit_customer",
  props: {
    address: {
      type: Object,
      default() {
        return {};
      },
    },
    customer_id: {
      type: Number,
      required: true,
    },
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Add Address" };
      },
    },
  },
  data() {
    return {
      loading: false,
      form: new Form({
        checkedaType: ["Billing Address", "Shipping Address"],
        customer_id: null,
        shipping_status: 0,
        billing_addresses: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: 8,
          post_code: null,
          type: "billing",
          latitude:null,
          longitude:null
        },
        shipping_addresses: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: 8,
          post_code: null,
          type: "shipping",
          latitude:null,
          longitude:null
        },
      }),
      city_billing_list: [],
      city_shipping_list: [],
      state_list: [],
      submit_btn_text: "Create",
      title_text: "Add Address",
      api_url: "/customer/create",
      response_msg: "Address created successfully.",
      atypes: ["Billing Address", "Shipping Address"],
    };
  },
  methods: {
    showHideFields(val) {
      let status = false;
      this.form.checkedaType.map((column) => {
        if (column == val) {
          status = true;
        }
      });

      if (val == "Shipping Address" && status == false) {
        this.form.shipping_status = 0;
        this.form.shipping_addresses = {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: 8,
          post_code: null,
          type: "shipping",
        };
      }

      if (val == "Billing Address" && status == false) {
        this.form.shipping_status = 0;
        this.form.billing_addresses = {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: 8,
          post_code: null,
          type: "billing",
        };
      }

      return status;
    },
    CreateCity() {
      this.$modal.show(
        CreateCity,
        { stateId: this.form.billing_addresses.state },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateCity1() {
      this.$modal.show(
        CreateCity,
        { stateId: this.form.shipping_addresses.state },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    setShippingAddress() {
      if (this.form.shipping_status == true) {
         this.$refs.shiporigin.clear();
        this.form.shipping_addresses.address_line_1 =
          this.form.billing_addresses.address_line_1;
        this.form.shipping_addresses.address_line_2 =
          this.form.billing_addresses.address_line_2;

        this.$refs.shiporigin.update(
          this.form.billing_addresses.address_line_2
        );  

        this.form.shipping_addresses.city = this.form.billing_addresses.city;
        this.form.shipping_addresses.state = this.form.billing_addresses.state;
        this.form.shipping_addresses.latitude = this.form.billing_addresses.latitude
        this.form.shipping_addresses.longitude = this.form.billing_addresses.longitude
        this.form.shipping_addresses.post_code = this.form.billing_addresses.post_code;
        this.form.shipping_addresses.type = "shipping";
      } else {
        this.form.shipping_addresses.address_line_1 = null;
        this.form.shipping_addresses.address_line_2 = null;
        this.form.shipping_addresses.city = null;
        this.form.shipping_addresses.state = null;
        this.form.shipping_addresses.post_code = null;
        this.form.shipping_addresses.latitude = null;
        this.form.shipping_addresses.longitude = null;
        this.form.shipping_addresses.type = "shipping";
      }
    },
    checkZip(value) {
      return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
    },
    validPostalbilling(postalcode) {
      this.form.errors.add("billing_post_code", "");
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.billing_addresses.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        this.form.errors.add("billing_post_code", "Invalid Postal Code");
        this.form.billing_addresses.post_code = "";
      }
    },
    validPostalshipping(postalcode) {
      this.form.errors.add("shipping_post_code", "");
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.shipping_addresses.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        this.form.errors.add("shipping_post_code", "Invalid Postal Code");
        this.form.shipping_addresses.post_code = "";
      }
    },
    getCityListByStateId(address_type) {
      this.loading = true;
      let state_id = null;
      if (address_type == "billing") {
        state_id = this.form.billing_addresses.state;
      } else if (address_type == "shipping") {
        state_id = this.form.shipping_addresses.state;
      }
      getCityListByStateId(state_id).then((response) => {
        if (address_type == "billing") {
          this.form.billing_addresses.city = null;
          this.city_billing_list = response.data.data;
        } else if (address_type == "shipping") {
          this.form.shipping_addresses.city = null;
          this.city_shipping_list = response.data.data;
        }
        this.loading = false;
      });
    },
    getCityList() {
      this.loading = true;
      getCityList().then((response) => {
        this.city_billing_list = response.data.data;
        this.city_shipping_list = response.data.data;
        this.loading = false;
      });
    },
    getStateList() {
      this.loading = true;
      getStateList().then((response) => {
        this.state_list = response.data.data;
        this.loading = false;
      });
    },
    getAddressBillingData: function (addressData) {
      this.form.billing_addresses.post_code = addressData.postal_code;
      this.form.billing_addresses.latitude = addressData.latitude
      this.form.billing_addresses.longitude = addressData.longitude
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name, "1");
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix, "1");
      }
      this.form.billing_addresses.address_line_2 =
        addressData.street_number + " " + addressData.route;
      this.address = addressData;
    },
    getAddressShippingData: function (addressData) {
      this.form.shipping_addresses.post_code = addressData.postal_code;
      this.form.shipping_addresses.latitude = addressData.latitude
      this.form.shipping_addresses.longitude = addressData.longitude
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name, "2");
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix, "2");
      }
      this.form.shipping_addresses.address_line_2 =
        addressData.street_number + " " + addressData.route;
      this.address = addressData;
    },
    getStateByStatePrefix(state_prefix, type) {
      getStateByPrefix(state_prefix).then((response) => {
        if (type == "0") {
          this.form.state_id = response.data.data.state_id;
        } else if (type == "1") {
          this.form.billing_addresses.state = response.data.data.state_id;
        } else {
          this.form.shipping_addresses.state = response.data.data.state_id;
        }
      });
    },
    getCityIdByName(city_name, type) {
      getCityByName(city_name).then((response) => {
        if (type == "1") {
          const billingStateId = response.data.data.state_id;
          const billingCityId = response.data.data.city_id;
          getCityListByStateId(billingStateId).then((response) => {
            this.form.city_id = null;
            this.city_billing_list = response.data.data;
            this.form.billing_addresses.city = billingCityId;
            this.city_loading = false;
          });
        } else {
          const ShippingStateId = response.data.data.state_id;
          const ShippingCityId = response.data.data.city_id;
          getCityListByStateId(ShippingStateId).then((response) => {
            this.form.city_id = null;
            this.city_shipping_list = response.data.data;
            this.form.shipping_addresses.city = ShippingCityId;
            this.city_loading = false;
          });
        }
      });
    },
    reset() {
      this.form.reset();
    },
    closeForm() {
      if (!this.form.submiting) {
        globalEvents.$emit("AddressAdded", false);
        this.$emit("close");
      }
    },
    submitForm() {
      this.loading = true;
      //  if(this.form.shipping_addresses.address_line_2 == '' || this.form.shipping_addresses.address_line_2 == null){
      //       this.form.shipping_addresses = []
      //  }
      //  if(this.form.billing_addresses.address_line_2 == '' || this.form.billing_addresses.address_line_2 == null){
      //     this.form.billing_addresses = []
      //  }
      const formURL = "/customer/address/create/" + this.customer_id;
      const msg = "Address has been created";
      this.form
        .post(formURL)
        .then((response) => {
          this.$message({
            message: msg,
            type: "success",
          });
          this.loading = false;
          globalEvents.$emit("AddressAdded", response.data);
          this.$emit("close");
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  created() {
    let address_id = this.$route.params.address_id;

    if (parseInt(address_id) > 0) {
      this.submit_btn_text = "Update Address";
      this.title_text = "Edit Address";
      this.response_msg = "Address updated successfully.";
    }
    this.getStateList();
    this.getCityList();
  },
  components: {
    VueGoogleAutocomplete,
  },
  mounted() {
    this.form.customer_id = this.customer_id;
    if (this.address.id) {
      this.form = new Form(this.address);
    }

    globalEvents.$on("cityname", (type) => {
      if (type) {
        this.city_billing_list.push(type);
      }
    });
  },
};
</script>